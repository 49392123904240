import { useState } from "react";
import { PlusCircleIcon, MinusCircleIcon } from "@heroicons/react/16/solid";
import { toast } from "react-toastify";
const DynamicQuestions = ({ surveyRequest, questionAnswer,onQuestionsChange }) => {
    const [questions, setQuestions] = useState([{ id: "" }]);
  
    const handleQuestionChange = (e, index) => {
      const updatedQuestions = [...questions];
      updatedQuestions[index].id = e.target.value;
      setQuestions(updatedQuestions);
      onQuestionsChange(updatedQuestions);
    };
  
    const addQuestion = (index) => {
      if (!questions[index].id.trim()) {
        toast.error(surveyRequest.message.emptyValue);
        return;
      }
      const updatedQuestions = [...questions, { id: "" }];
      setQuestions(updatedQuestions);
      onQuestionsChange(updatedQuestions); 
    };
  
    const deleteQuestion = (index) => {
      if (questions.length <= 1) {
        toast.error(surveyRequest.message.lastValue);
        return;
      }
      const updatedQuestions = questions.filter((_, i) => i !== index);
      setQuestions(updatedQuestions);
      onQuestionsChange(updatedQuestions);
    };
  
    return (
      <div>
        {questions.map((question, index) => (
          <div className="grid grid-cols-6 md:grid-cols-8 gap-4" key={index}>
            <div className="col-start-1 col-end-7">
              <label htmlFor={`question-${index}`} className="block custom-blue-text mb-1">
                {surveyRequest.message.questionField}
              </label>
              <select
                id={`question-${index}`}
                name={`question-${index}`}
                value={question.id || ""}
                onChange={(e) => handleQuestionChange(e, index)}
                className="w-full border border-[#1D2A42] rounded-md px-3 py-2 bg-transparent"
              >
                <option value="" disabled>
                  {question.id ? surveyRequest.message.selectDiffernet : surveyRequest.message.select}
                </option>
                {questionAnswer.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.questionName}
                  </option>
                ))}
              </select>
            </div>
  
            <div className="flex mt-8 col-start-7 col-end-9">
              <PlusCircleIcon
                onClick={() => addQuestion(index)}
                className="w-8 h-8 cursor-pointer hover:text-blue-500"
              />
              <MinusCircleIcon
                onClick={() => deleteQuestion(index)}
                className="w-8 h-8 ml-4 cursor-pointer hover:text-blue-500"
              />
            </div>
          </div>
        ))}
      </div>
    );
  };
  
  export default DynamicQuestions;